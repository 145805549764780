import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowRight } from "./assets/arrow_right.svg";

const Container = styled.div`
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background: #f6f7fb;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #222222;
  font-size: ${({ isLargeFont }) => (isLargeFont ? "14px" : "12px")};
  line-height: 24px;
  padding-right: 8px;
  font-weight: 400;
`;

const tokensForLargerFont = [
  "6c61d2b45b1c72dcab5cf0c518bee6fabd2cc049",
  "46fd9c4527a23e01c5df284e82ff7e91946b01e7",
  "7074fafbed619be5399d6ef37d10514d03f4fe0d",
  "f87118098be828b28084e6ab206444a8a05c50e1",
];

const QuestionItem = ({ question, token, onClick, index }) => {
  const isLargeFont = tokensForLargerFont.includes(token);

  return (
    <Container onClick={onClick}>
      <Label
        isLargeFont={isLargeFont}
        role="button"
        className="question-item-text-display"
        tabIndex={index}
      >
        {question}
      </Label>
      <ArrowRight />
    </Container>
  );
};

export default QuestionItem;
