import React from "react";
import styled from "styled-components";
import { strings } from "../../../../utils/localization";
import { Iconset } from "../../../../components/Icons/Icons";

const ChatWrapper = styled.div`
  font-size: 12px;
  max-width: 90%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
`;

const MessageWithImage = styled.div`
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  position: relative;
  animation: slideMatadorAnimation 0.5s forwards;

  @keyframes slideMatadorAnimation {
    0% {
      left: -100px;
    }
    100% {
      left: 0;
    }
  }
`;

const ImgWrapper = styled.div`
  font-size: 12px;
  background-color: #d0d1dd;
  min-width: 36px;
  max-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageContainer = styled.div`
  border-radius: 13px;
  word-break: break-word;
  background-color: #fff;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #cdd9ea;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Phone = styled.div`
  font-size: 10px !important;
  border-radius: 13px 13px 0 0;
  text-align: center;
  padding: 5px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  background-color: rgb(241, 210, 101);
`;

const H2 = styled.h2`
  word-break: break-word;
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 0;
  padding: 0 20px;
`;

const Text = styled.div`
  word-break: break-word;
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
  color: #474657;
  font-weight: 500;
  white-space: pre-line;
  line-height: 19px;
  text-align: center;
  padding: 7px 10px;
`;

const CheckWrapper = styled.div`
  word-break: break-word;
  -webkit-font-smoothing: auto;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  background-color: rgb(241, 210, 101);
`;

const WeGotYourMessage = ({
  organization_name,
  backgroundColor,
  img,
  second_message,
}) => {
  return (
    <ChatWrapper>
      <MessageWithImage>
        <ImgWrapper>
          {img ? <Img src={img} alt="We gott your message" /> : Iconset.noImage}
        </ImgWrapper>
        <MessageContainer>
          <Phone style={{ backgroundColor }}>{organization_name}</Phone>
          <H2>{strings.WE_GOT_YOUR_MESSAGE}!</H2>
          <Text>{second_message}</Text>
          <CheckWrapper style={{ backgroundColor }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="9.429"
              viewBox="0 0 12 9.429"
            >
              <path
                id="Path_747"
                data-name="Path 747"
                d="M28.911,978.1l-.81.826c-1.81,1.849-3.463,3.661-5.2,5.47l-2.044-1.67-.894-.729-1.43,1.821.894.729,2.86,2.331.8.65.727-.741c2.057-2.1,3.9-4.164,5.911-6.217l.81-.826L28.911,978.1Z"
                transform="translate(-18.531 -978.097)"
                fill="#f4f7fc"
              />
            </svg>
          </CheckWrapper>
        </MessageContainer>
      </MessageWithImage>
    </ChatWrapper>
  );
};

export default WeGotYourMessage;
