import { useEffect, useState } from "react";

const useSecondaryColor = (token) => {
  const [secondaryColor, setSecondaryColor] = useState("#fff");
  const [timeColor, setTimeColor] = useState("");

  useEffect(() => {
    setColor();
  }, [token]);

  const setColor = () => {
    if (
      token === "fe9ab739c284a799c8653dd5876bffdff9f6cd16" ||
      token === "846636659326104655665db84942a71371f49dce" ||
      token === "0217b1f6b04343a1dd0fe119724e1dab72cee621"
    ) {
      setSecondaryColor("#222222");
      setTimeColor("#222222");
    }
  };

  return {
    secondaryColor,
    timeColor,
  };
};

export default useSecondaryColor;
