import React from "react";
import styled from "styled-components";
import { strings } from "../../utils/localization";

import SMSIcon from "./assets/message.png";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 0px;
  color: ${(props) => props.textColor};
  font-size: ${(props) => `${props.fontSize}px`};
  margin-left: 5px;
`;

const Icon = styled.img`
  height: 18px;
  width: 23px;
`;

const CtaThankYou = ({ textColor = "#FFFFFF", onClick, fontSize }) => {
  return (
    <Container onClick={onClick}>
      <Icon src={SMSIcon} alt={strings.CTA_THANK_YOU} />
      <Text fontSize={fontSize} textColor={textColor}>
        {strings.CTA_THANK_YOU}
      </Text>
    </Container>
  );
};

export default CtaThankYou;
