import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const TrackingEvents = {
  ASC_COMM_ENGAGEMENT: "asc_comm_engagement",
  ASC_COMM_SUBMISSION: "asc_comm_submission",
  ASC_FORM_ENGAGEMENT: "asc_form_engagement",
  ASC_FORM_SUBMISSION: "asc_form_submission",
  ASC_CTA_INTERACTION: "asc_cta_interaction",
};

const useAnalytics = (trackingNumber) => {
  const [ctaInteractionSent, setCtaInteractionSent] = useState(false);
  const [formEngagementSent, setFormEngagementSent] = useState(false);

  useEffect(() => {
    if (!ReactGA?.isInitialized && trackingNumber) {
      ReactGA.initialize(trackingNumber, {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
          titleCase: false,
          send_page_view: false,
        },
      });

      window.gtag("config", trackingNumber, {
        send_page_view: false,
      });
    }
  }, [trackingNumber]);

  const sendCommEngagementEvent = ({ widget, status = "start" }) => {
    sendEvent({ name: TrackingEvents.ASC_COMM_ENGAGEMENT, widget, status });
  };

  const sendCommSubmissionEvent = ({ widget, status = "start" }) => {
    sendEvent({ name: TrackingEvents.ASC_COMM_SUBMISSION, widget, status });
  };

  const sendFormEngagementEvent = ({ widget }) => {
    if (!formEngagementSent) {
      setFormEngagementSent(true);
      sendEvent({ name: TrackingEvents.ASC_FORM_ENGAGEMENT, widget });
    }
  };

  const sendFormSubmissionEvent = ({ widget }) => {
    sendEvent({ name: TrackingEvents.ASC_FORM_SUBMISSION, widget });
  };

  const sendCtaInteractionEvent = ({ widget }) => {
    if (!ctaInteractionSent) {
      setCtaInteractionSent(true);
      sendEvent({ name: TrackingEvents.ASC_CTA_INTERACTION, widget });
    }
  };

  const sendEvent = ({ name, widget, status }) => {
    if (ReactGA.isInitialized) {
      ReactGA.event(name, {
        comm_type: widget,
        event_owner: "matador",
        ...(status ? { status } : {}),
      });
    }
  };

  return {
    sendCommEngagementEvent,
    sendCommSubmissionEvent,
    sendFormEngagementEvent,
    sendFormSubmissionEvent,
    sendCtaInteractionEvent,
  };
};

export default useAnalytics;
