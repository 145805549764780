import React, { useCallback, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import querystring from "querystring";
import moment from "moment";

import ChatBoxPreview from "../ChatBoxPreview";
import ChatBoxPreviewLight from "../ChatBoxPreviewLight";

import chatSettingsMainActions from "../modules/chatSettingsMainActions";
import PageLoader from "../PageLoader";
import { getLanguage, strings } from "../../utils/localization";
import QuestionsApi from "../../api/questions-api";
import useAnalytics from "../../utils/useAnalytics";

const cookies = new Cookies();

const ChatBox = (props) => {
  const { theme } = props;

  const [viewIndex, setViewIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState();
  const [parentURL, setParentURL] = useState();
  const [chatData, setChatData] = useState();
  const [backView, setBackView] = useState();
  const [questionId, setQuestionId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [listQuestions, setListQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [dateQuestion, setDateQuestion] = useState(moment());
  const [typingTwo, setTypingTwo] = useState(true);
  const [typingThree, setTypingThree] = useState(true);
  const [_trigger_id, set_trigger_id] = useState();
  const [messagesEndRef, setMessagesEndRef] = useState();
  const { sendCommSubmissionEvent } = useAnalytics(chatData?.GATrackingNumber);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const itemIdValue = props.match.params.token ? props.match.params.token : 0;
    setItemId(itemIdValue);

    let languageValue = props.match.params.lang
      ? props.match.params.lang
      : "en";
    if (languageValue === "sp") {
      languageValue = "es";
    }

    strings.setLanguage(languageValue);

    let params = querystring.parse(props.location.search);
    let urlValue = decodeURI(params["?url"]);

    if (props.location.hash) {
      const newUrl = urlValue + props.location.hash;
      setParentURL(newUrl);
      urlValue = newUrl;
    } else {
      setParentURL(urlValue);
    }

    let result = await chatSettingsMainActions.getSingleForWidget(
      { language: props.match.params.lang || "en" },
      itemIdValue
    );

    if (result.success) {
      const { triggers } = result.data;
      // TODO: Do we need to set language again based on previous logic?
      // languageValue = result.data.chatData.language;
      // strings.setLanguage(languageValue)

      setChatData(result.data.chatData);
      setLoading(false);

      setTriggers({ triggers, parentURL: urlValue });
    } else {
      setLoading(false);
      props.history.push({
        pathname: "/error",
        state: { status: "400 " },
      });
    }
  };

  const setTriggers = ({ triggers, parentURL }) => {
    let hideConditionMatched = false;
    let matchedTriggerId = null; // To store the ID of the trigger that matches the conditions.

    triggers.forEach((trig) => {
      if (trig.enabled) {
        trig.conditions.url.forEach((condition) => {
          let processedLink = condition.link
            .replace(/(^\w+:|^)\/\/(www\.)?/, "")
            .replace(/\/$/, "");
          let conditionMatch = false;

          switch (condition.condition) {
            case "contains":
              conditionMatch = parentURL.includes(processedLink);
              break;
            case "starts_with":
              conditionMatch = parentURL.startsWith(processedLink);
              break;
            case "matches":
              conditionMatch = parentURL === processedLink;
              break;
            case "ends_with":
              conditionMatch = parentURL.endsWith(processedLink);
              break;
            default:
              break;
          }

          if (conditionMatch) {
            // Prioritize hide conditions
            if (condition.action === "hide") {
              hideConditionMatched = true;
              matchedTriggerId = trig._id; // Capture the ID of the matching trigger
            } else if (condition.action === "show" && !hideConditionMatched) {
              // Show only if no hide condition has been matched yet
              matchedTriggerId = trig._id; // Capture the ID of the matching trigger
            }
          }
        });
      }
    });

    // If matchedTriggerId is not null, call renderList with the questions from the matched trigger
    if (matchedTriggerId !== null) {
      const matchedTrigger = triggers.find(
        (trig) => trig._id === matchedTriggerId
      );
      if (matchedTrigger) {
        renderList(matchedTrigger.questions, false, matchedTriggerId);
      }
    } else {
      // Fallback to default trigger action if no specific conditions are matched or if showContent is true without a specific match
      const defaultTrigger = triggers.find((trig) => trig.isDefault);
      if (defaultTrigger) {
        renderList(defaultTrigger.questions, false, defaultTrigger._id);
      }
    }
  };

  const handleCalculatetriggerCount = async (trigger_id) => {
    if (trigger_id) {
      await chatSettingsMainActions.setSendTriggerId(trigger_id);
    }
  };

  const renderList = (questions, open, _trigger_id = null) => {
    setListQuestions(questions);
    set_trigger_id(_trigger_id);
    handleCalculatetriggerCount(_trigger_id);
  };

  const isValidPhoneNumber = () => {
    if (phoneNumber?.startsWith("04")) {
      return phoneNumber.length === 10 || firstName;
    }
    return phoneNumber?.length === 11 || firstName;
  };

  const getPhoneNumber = () => {
    if (phoneNumber.startsWith("04")) {
      return `61${phoneNumber.substring(1)}`;
    }
    return phoneNumber;
  };

  const sendQuestion = (cb) => {
    if (phoneNumber && isValidPhoneNumber() && _trigger_id) {
      let param = {
        phone_number: getPhoneNumber(),
        question: selectedQuestion,
        link: parentURL,
        _trigger_id: _trigger_id,
        language: getLanguage(props.match.params.lang),
      };

      if (questionId) {
        param._question_id = questionId;
      }

      if (chatData?.firstAndLastNameSeparately) {
        param.firstName = firstName;
        param.lastName = lastName;
      } else if (firstName) {
        param.firstName = firstName;
      }

      QuestionsApi.sendQuestion({ itemId: itemId, param }).then((response) => {
        cb && cb(response);
      });
    }
  };

  const onBackClick = () => {
    setViewIndex(1);
    setSelectedQuestion("");
    setBackView("backView");
    setQuestionId(null);

    setTimeout(() => {
      setBackView("");
      setTypingTwo(true);
      setTypingThree(true);
    }, 1000);
  };

  const gtag = () => {
    sendCommSubmissionEvent({ widget: "Connect" });
  };

  const submit = ({ viewIndex, send }) => {
    setViewIndex(viewIndex);

    if (send) {
      sendQuestion(() => {
        setTimeout(() => {
          setTypingThree(false);
          scrollToBottom();
        }, 2000);
      }, gtag());
    } else {
      setTimeout(() => {
        setTypingThree(false);
        scrollToBottom();
      }, 2000);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef) {
      const scrollHeight = messagesEndRef.scrollHeight;
      const height = messagesEndRef.clientHeight;
      const maxScrollTop = scrollHeight - height;

      messagesEndRef.scrollTo({
        top: maxScrollTop > 0 ? maxScrollTop : 0,
        behavior: "smooth",
      });
    }
  };

  const handleSelectQuestion = (question) => {
    setViewIndex(2);
    setSelectedQuestion(question);
    setDateQuestion(moment().format("MM.DD.YYYY HH:MM a"));

    setTimeout(() => {
      setTypingTwo(false);
    }, 3000);
  };

  const renderLoad = () => {
    cookies.set("token", props.match.params.token);
    cookies.set("lang", props.match.params.lang);
    return <PageLoader />;
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (chatData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    setPhoneNumber(pasteValue);
  };

  if (loading && !chatData) {
    renderLoad();
  }

  const clear = () => {
    setViewIndex(1);
  };

  const onMessageReceivedFromIframe = useCallback(
    ({ data }) => {
      setChatData({
        ...chatData,
        ...data,
      });

      if (data.type === "MATADOR-CLEAR-CONNECT") {
        clear();
      }
    },
    [chatData]
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  if (theme === "light") {
    return (
      <ChatBoxPreviewLight
        {...props}
        viewIndex={viewIndex}
        setViewIndex={(val) => setViewIndex(val)}
        setLoading={(value) => setLoading(value)}
        chatData={chatData}
        submit={submit}
        onBackClick={onBackClick}
        handleSelectQuestion={handleSelectQuestion}
        selectedQuestion={selectedQuestion}
        backView={backView}
        listQuestions={listQuestions}
        dateQuestion={dateQuestion}
        typingTwo={typingTwo}
        typingThree={typingThree}
        setPhoneNumber={setPhoneNumber}
        setSelectedQuestion={setSelectedQuestion}
        setQuestionId={setQuestionId}
        setMessagesEndRef={setMessagesEndRef}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phoneNumber={phoneNumber}
        defaultCountryFlag={
          chatData?.organization_country_code?.toLowerCase() || "us"
        }
        isValidPhoneNumber={isValidPhoneNumber()}
        getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
      />
    );
  }

  return (
    <>
      <ChatBoxPreview
        {...props}
        viewIndex={viewIndex}
        setViewIndex={(val) => setViewIndex(val)}
        setLoading={(value) => setLoading(value)}
        chatData={chatData}
        submit={submit}
        onBackClick={onBackClick}
        handleSelectQuestion={handleSelectQuestion}
        selectedQuestion={selectedQuestion}
        backView={backView}
        listQuestions={listQuestions}
        dateQuestion={dateQuestion}
        typingTwo={typingTwo}
        typingThree={typingThree}
        setPhoneNumber={setPhoneNumber}
        setSelectedQuestion={setSelectedQuestion}
        setQuestionId={setQuestionId}
        setMessagesEndRef={setMessagesEndRef}
        phoneNumber={phoneNumber}
        defaultCountryFlag={
          chatData?.organization_country_code?.toLowerCase() || "us"
        }
        isValidPhoneNumber={isValidPhoneNumber()}
        getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
        scrollToBottom={scrollToBottom}
      />
    </>
  );
};

export default ChatBox;
