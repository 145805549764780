import React from "react";
import styled from "styled-components";
import Linkify from "linkify-react";

import { ReactComponent as SuccessIcon } from "./assets/check-icon.svg";
import { strings } from "../../../../utils/localization";
import useSecondaryColor from "../../../utils/useSecondaryColor";

const Container = styled.div`
  padding: 5px 0px 20px 10px;
`;

const RightMessageContainer = styled.div`
  display: flex;
  width: 200px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  border-radius: 12px 12px 0px 12px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  padding-right: 15px;
  position: relative;

  animation: slideMatadorAnimation 0.5s forwards;

  @keyframes slideMatadorAnimation {
    0% {
      left: -100px;
    }
    100% {
      left: 0;
    }
  }
`;

const LeftMessageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 12px 12px 12px 0px;
  border: 1px solid #cdd9ea;
  margin-left: 6px;
  max-width: 80%;
  margin-left: 30px;
`;

const MessageText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  word-break: break-word;
  color: ${(props) => props.color};
`;

const Time = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  color: ${(props) => props.color || "var(--main-20, #dff2eb)"};
`;

const Status = styled.div`
  color: #7e7e7e;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

const Text = styled.span`
  margin-left: 2px;
`;

const Icon = styled.div`
  margin-bottom: 1px;
`;

const InitialResponse = ({
  img,
  dateQuestion,
  selectedQuestion,
  answer,
  firstMessage,
  questionType,
  primaryColor,
  isCustomSubmitForm,
  token,
}) => {
  const { secondaryColor, timeColor } = useSecondaryColor(token);

  const splitText = answer?.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      <Linkify options={{ target: "_blank" }}>{line}</Linkify>
      <br />
    </React.Fragment>
  ));

  return (
    <Container>
      {!isCustomSubmitForm && (
        <RightContainer>
          <RightMessageContainer style={{ backgroundColor: primaryColor }}>
            <MessageText color={secondaryColor}>{selectedQuestion}</MessageText>
            <Time color={timeColor}>{dateQuestion}</Time>
          </RightMessageContainer>
          <Status>
            <Icon>
              <SuccessIcon />
            </Icon>
            <Text>{strings.DELIVERED}</Text>
          </Status>
        </RightContainer>
      )}

      <LeftContainer>
        <LeftMessageContainer>
          <MessageText style={{ color: "#222" }}>
            {questionType === "SMART_ANSWER" ? (
              <>{answer ? <>{splitText}</> : null}</>
            ) : (
              <>{firstMessage}</>
            )}
          </MessageText>
        </LeftMessageContainer>
      </LeftContainer>
    </Container>
  );
};

export default InitialResponse;
