const moment = require("moment-timezone");

// will return Monday, Tuesday, Wednesday, etc.
const getDayOfWeek = ({ date }) => {
  if (!date) {
    return;
  }
  return moment(date).format("dddd");
};

// will return the time now in the timezone provided
const dateNowInTimezone = ({ timezone }) => {
  if (!timezone) {
    return;
  }
  return moment().tz(timezone);
};

// returns from and to working hours and if it is open for the given day of the week provided
const getFromAndToWorkingHoursForDayOfWeek = ({
  workingHours,
  dayOfWeekKey,
}) => {
  if (!workingHours || !dayOfWeekKey) {
    return;
  }
  const dayWorkingHours = workingHours[dayOfWeekKey];
  if (!dayWorkingHours) {
    return;
  }
  const fromHours = dayWorkingHours["from"];
  const toHours = dayWorkingHours["to"];
  const isOpenOnThisWeekDay = dayWorkingHours["isOpen"];
  return { fromHours, toHours, isOpenOnThisWeekDay };
};

const getIsStoreOpen = ({
  timezoneDate,
  fromHours,
  toHours,
  isOpenOnThisWeekDay,
}) => {
  if (!timezoneDate || !fromHours || !toHours || !isOpenOnThisWeekDay) {
    return;
  }
  if (!isOpenOnThisWeekDay) {
    return false;
  }
  const tZHoursMinuteDate = moment(timezoneDate, ["h:mm A"]);
  const dtFrom = moment(fromHours, ["h:mm A"]);
  const dtTo = moment(toHours, ["h:mm A"]);
  const from = moment(dtFrom).format("HHmm");
  const to = moment(dtTo).format("HHmm");
  const now = moment(tZHoursMinuteDate).format("HHmm");

  // Check if the store is open 24 hours per day
  if (from === to) {
    return true;
  }

  // Handle the case where 'to' is less than 'from' (e.g., from: "9:00 PM", to: "1:00 AM")
  if (to < from) {
    return now >= from || now < to;
  }
  return now >= from && now < to;
};

const isStoreOpen = ({
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
}) => {
  const timezoneDate = dateNowInTimezone({ timezone: organizationTimeZone });
  const currentDay = moment(timezoneDate).format("YYYY-MM-DD");

  let workingHoursForToday = {};

  const customHoursForToday = organizationCustomWorkingHours.find(
    (customHour) => customHour.date === currentDay
  );

  if (customHoursForToday) {
    workingHoursForToday = {
      [getDayOfWeek({ date: timezoneDate })]: {
        isOpen: customHoursForToday.isOpen,
        from: customHoursForToday.from,
        to: customHoursForToday.to,
      },
    };
  } else {
    const dayOfWeekKey = getDayOfWeek({ date: timezoneDate });
    workingHoursForToday = {
      [dayOfWeekKey]: organizationWorkingHours[dayOfWeekKey],
    };
  }

  const result = getFromAndToWorkingHoursForDayOfWeek({
    workingHours: workingHoursForToday,
    dayOfWeekKey: getDayOfWeek({ date: timezoneDate }),
  });

  const { fromHours, toHours, isOpenOnThisWeekDay } = result;
  return getIsStoreOpen({
    timezoneDate,
    fromHours,
    toHours,
    isOpenOnThisWeekDay,
  });
};

export default isStoreOpen;
