import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../styles/createCoupon.css";
import couponMainActions from "../components/modules/couponMainActions";
import { ReactComponent as CloseIcon } from "../assets/images/common/closeIcon.svg";
import MetaButton from "../components/Buttons/SubmitButton";
import PhoneNumberField from "../components/Fields/PhoneNumberField";
import querystring from "querystring";
import QuestionsApi from "../api/questions-api";
import { getLanguage, strings } from "../utils/localization";
import { ReactComponent as MessageIcon } from "./assets/message-icon.svg";
import AcceptTermsFooter from "../v2/components/Footer/AcceptTermsFooter";
import useAnalytics from "../utils/useAnalytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
`;

const ThankYouText = styled.p`
  margin: 0px;
  color: ${(props) => props.textColor};
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 3px;
`;

const ThankYouContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 87px;
  width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopCoupon = (props) => {
  const { token: locationId } = props.match.params;
  const [language, setLanguage] = useState("en");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [couponData, setCouponData] = useState();
  const [contentVisible, setContentVisible] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [parentURL, setParentURL] = useState();
  const {
    sendCommSubmissionEvent,
    sendCommEngagementEvent,
    sendCtaInteractionEvent,
  } = useAnalytics(couponData?.GATrackingNumber);

  useEffect(() => {
    if (couponData) {
      sendCommEngagementEvent({ widget: "Desktop Coupon", status: "load" });
      setContentVisible(true);
    }
    setLanguageValue();
  }, [couponData]);

  const onClick = () => {
    sendCtaInteractionEvent({ widget: "Exit Intent" });
  };

  const setLanguageValue = () => {
    let { lang } = props.match.params;

    let languageValue = lang ? lang : "en";
    if (languageValue === "es") {
      languageValue = "sp";
    }

    setLanguage(languageValue);
    if (languageValue === "sp") {
      strings.setLanguage("es");
    } else {
      strings.setLanguage(languageValue);
    }
  };

  const GAEventTracking = () => {
    sendCommSubmissionEvent({ widget: "Desktop Coupon" });
  };

  useEffect(() => {
    const fill = async () => {
      let params = querystring.parse(props.location.search);
      let urlValue = decodeURI(params["?url"]);

      let result = await couponMainActions.getCoupon(locationId);
      if (result.success) {
        setCouponData(result.data);

        if (props.location.hash) {
          const newUrl = urlValue + props.location.hash;
          setParentURL(newUrl);
        } else {
          setParentURL(urlValue);
        }
      }
    };
    if (locationId) {
      fill();
    }
  }, [locationId]);

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (couponData?.organization_country_code?.toLowerCase() === "au") {
      if (pasteValue.length === 9) {
        pasteValue = `0${pasteValue}`;
      }
      if (pasteValue.length >= 11) {
        pasteValue = `0${pasteValue && pasteValue.slice(2)}`;
      }
    } else {
      if (pasteValue.length === 10) {
        pasteValue = `1${pasteValue}`;
      }
      if (pasteValue.length === 11) {
        pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
      }
    }

    setPhoneNumber(pasteValue);
  };

  const isValidPhoneNumber = () => {
    if (phoneNumber?.startsWith("04")) {
      return phoneNumber.length === 10;
    }
    return phoneNumber?.length === 11;
  };

  const submitForm = async () => {
    GAEventTracking();

    let param = {
      phone_number: phoneNumber,
      firstName: "",
      link: parentURL,
      question: "Desktop coupon",
      language: getLanguage(language),
    };

    await QuestionsApi.desktopCoupon({
      locationId: couponData._location_id,
      param,
    });
    setFormSubmitted(true);
  };

  if (!couponData || !couponData?.showOnDesktop) return null;

  const title =
    couponData.title?.find((x) => x.language === language)?.text ||
    couponData.title?.find((x) => x.language === "en")?.text;
  const description =
    couponData.description?.find((x) => x.language === language)?.text ||
    couponData.description?.find((x) => x.language === "en")?.text;

  const getContent = () => {
    return (
      <div
        className="phone-under-nav-wrapper"
        style={{
          padding: "8px",
          height: "100%",
          backgroundColor: couponData.primaryBackgroundColor,
        }}
      >
        <div
          onClick={close}
          style={{
            padding: 10,
            cursor: "pointer",
            right: 15,
            top: 10,
            position: "absolute",
          }}
        >
          <CloseIcon stroke={couponData.titleColor} />
        </div>
        <div style={{ margin: 0 }} className="logo-item">
          {couponData.logoImageUrl && (
            <img
              style={{ height: 118, width: 189 }}
              alt="Cover"
              src={couponData.logoImageUrl}
            />
          )}
        </div>
        <div className="title-item">
          {title && (
            <div className="title" style={{ color: couponData.titleColor }}>
              {title}
            </div>
          )}
        </div>
        <div className="note-item">
          {description && (
            <div
              className="note"
              style={{ color: couponData.descriptionColor }}
            >
              {description}
            </div>
          )}
        </div>
        {formSubmitted ? (
          <ThankYouContainer className="fade-in">
            <MessageIcon height={18} width={23} fill={couponData.titleColor} />
            <ThankYouText fontSize="14" textColor={couponData.titleColor}>
              {strings.CTA_THANK_YOU}
            </ThankYouText>
          </ThankYouContainer>
        ) : (
          <div style={{ marginTop: "auto" }} className="button-item">
            <div className="matadot-live-chat-123789-write-text-message">
              <PhoneNumberField
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                defaultCountryFlag={
                  couponData?.organizationCountryCode?.toLowerCase() || "us"
                }
                getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              />
              <MetaButton
                style={{ backgroundColor: couponData.backgroundColor }}
                onClick={submitForm}
                disabled={!isValidPhoneNumber()}
              />
            </div>
          </div>
        )}

        <div>
          <AcceptTermsFooter
            color={couponData.titleColor}
            phoneAdded={formSubmitted}
            termsLink={couponData.termsConditionsLink}
            paddingLeft={10}
          />
        </div>
      </div>
    );
  };

  const close = () => {
    window.top.postMessage("matador_desktop_coupon_message_hidden", "*");
    setContentVisible(false);
  };

  if (!contentVisible) return null;

  return <Container onClick={onClick}>{getContent()}</Container>;
};

export default DesktopCoupon;
