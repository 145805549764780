import React from "react";
import styled from "styled-components";

import MessageIcon from "./assets/messageIcon.png";

const Container = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 0px;
  color: ${(props) => props.textColor};
  font-size: ${(props) => `${props.fontSize}px`};
  margin-left: 3px;
`;

const Icon = styled.img`
  height: 23px;
  width: 23px;
  margin-right: 3px;
`;

const CtaInitialMessage = ({
  text,
  textColor = "#FFFFFF",
  onClick,
  fontSize,
}) => {
  return (
    <Container id="initial-message-cta-matador" onClick={onClick}>
      <Icon src={MessageIcon} alt={text} />
      <Text fontSize={fontSize} textColor={textColor}>
        {text}
      </Text>
    </Container>
  );
};

export default CtaInitialMessage;
