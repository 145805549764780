import React, { useState } from "react";
import "../../../../styles/LiveChatStyle.css";
import ChatContent from "../../../../components/Chat/ChatContent";
import SuccessMessage from "../../../../components/Chat/SuccessMessage";
import PhoneNumberField from "../../../../components/Fields/PhoneNumberField";
import MetaButton from "../../../../components/Buttons/SubmitButton";
import styled from "styled-components";
import { strings } from "../../../../utils/localization";
import AcceptTermsFooter from "../../Footer/AcceptTermsFooter";
import MatadorInput from "../../Input/MatadorInput";

const MAX_CHARACTERS = 25;

const Container = styled.div`
  * {
    font-size: 12px;

    /* Temp solution until we replace legacy components */
  }

  .matadot-live-chat-123789-text {
    font-size: 12px !important;
  }

  .matadot-live-chat-123789-date {
    font-size: 10px !important;
  }

  .matadot-live-chat-123789-phone {
    font-size: 10px !important;
  }

  .matadot-live-chat-123789-chatbox
    .matadot-live-chat-123789-chatbox-middle
    .matador-chat {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      margin-bottom: 0px;
    }

    @supports (scrollbar-color: #00000033 transparent) {
      scrollbar-color: #00000033 transparent;
      scrollbar-width: thin;
    }
  }

  #matador-input-name {
    height: auto;
    padding-top: 5px;
    margin-top: 10px;
  }

  #matador-input-label {
    transform: translateY(-5px);
  }
`;

const ChatBoxPreviewLegacy = (props) => {
  const [phoneAdded, setPhoneAdded] = useState();

  const renderChatBoxTwoAndTree = () => {
    let {
      chatData,
      selectedQuestion,
      dateQuestion,
      phoneNumber,
      typingTwo,
      submit,
      viewIndex,
      typingThree,
      setPhoneNumber,
      setMessagesEndRef,
      defaultCountryFlag,
      isValidPhoneNumber,
      getPhoneNumberFromClipboard,
      isClientUIX,
      questionType,
      answer,
      scrollToBottom,
      name,
      setName,
    } = props;

    const handleSubmit = () => {
      if (!phoneAdded) {
        return setPhoneAdded(true);
      }
      setPhoneAdded(false);
      return submit({ viewIndex: 3, send: true });
    };

    const handleChange = (value) => {
      if (value.length <= MAX_CHARACTERS) {
        setName(value);
      }
    };

    let location_name =
      chatData && chatData.location_name ? chatData.location_name : "";
    let organization_name =
      chatData && chatData.organization_name ? chatData.organization_name : "";
    let backgroundColor = chatData && chatData.color ? chatData.color : "red";
    let first_message =
      chatData && chatData.texts.first_message
        ? chatData.texts.first_message
        : "";
    let second_message =
      chatData && chatData.texts.second_message
        ? chatData.texts.second_message
        : "";
    let color = chatData && chatData.color ? chatData.color : "white";
    let img = chatData && chatData.image_url ? chatData.image_url : "";

    return (
      <>
        <div
          className={
            "matadot-live-chat-123789-chatbox-middle " +
            ((viewIndex === 2 &&
              "matadot-live-chat-123789-chatbox-middle-two") ||
              (viewIndex === 3 &&
                "matadot-live-chat-123789-chatbox-middle-three"))
          }
          style={{
            height: viewIndex === 2 || viewIndex === 3 ? "100%" : "auto",
            paddingTop: viewIndex === 2 || viewIndex === 3 ? "0px" : "5px",
          }}
        >
          <div
            id="content-area"
            ref={(el) => {
              setMessagesEndRef(el);
            }}
            className="matadot-live-chat-123789-questons-scrollbars matador-chat"
            style={{
              paddingTop: viewIndex === 2 || viewIndex === 3 ? "20px" : "0px",
              paddingBottom: 5,
            }}
          >
            <ChatContent
              backgroundColor={backgroundColor}
              phoneNumber={phoneNumber}
              dateQuestion={dateQuestion}
              typingTwo={typingTwo}
              selectedQuestion={selectedQuestion}
              img={img}
              location_name={location_name}
              first_message={first_message}
              viewIndex={viewIndex}
              color={color}
              isClientUIX={isClientUIX}
              questionType={questionType}
              answer={answer}
              scrollToBottom={scrollToBottom}
            />
            {viewIndex === 3 && (
              <SuccessMessage
                typingThree={typingThree}
                img={img}
                organization_name={organization_name}
                color={color}
                second_message={second_message}
                backgroundColor={backgroundColor}
              />
            )}
          </div>
        </div>
        {viewIndex !== 3 && (
          <div className="matadot-live-chat-123789-write-text-message">
            {phoneAdded ? (
              <MatadorInput
                placeholder={strings.WHATS_YOUR_NAME}
                color={backgroundColor}
                value={name}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                borderDisabled
              />
            ) : (
              <PhoneNumberField
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                defaultCountryFlag={defaultCountryFlag}
                getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
              />
            )}

            <MetaButton
              style={{ backgroundColor: color }}
              onClick={handleSubmit}
              disabled={!isValidPhoneNumber}
            />
          </div>
        )}
      </>
    );
  };

  const getMainContent = (viewIndex) => {
    if (viewIndex === 2 || viewIndex === 3) {
      return renderChatBoxTwoAndTree();
    }
  };

  const { viewIndex, chatData, backView } = props;
  if (!chatData) {
    return null;
  }

  const mainContent = getMainContent(viewIndex);

  return (
    <Container
      className="matadot-live-chat-123789-chatbox-preview matador-chat"
      style={{ backgroundColor: "#fff" }}
    >
      <div
        className={
          `matadot-live-chat-123789-chatbox matadot-live-chat-123789-chatbox-current-view-${viewIndex}` +
          (backView && backView ? backView : "")
        }
        style={{ height: chatData.height, boxShadow: "none" }}
      >
        {mainContent}
        <AcceptTermsFooter
          color={chatData.termsLink}
          phoneAdded={phoneAdded || viewIndex === 3}
          termsLink={chatData.termsLink}
          paddingLeft={10}
        />
      </div>
    </Container>
  );
};

export default ChatBoxPreviewLegacy;
